<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pageOptions"
                      :clearable="false" class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1" :placeholder="$t('Search...')"/>
              <b-button v-on:click="updateItems()" class="mr-1" variant="success"><span class="text-nowrap"
              >{{ $t('Mark All as read') }}</span>
              </b-button>
              <b-button v-on:click="deleteAllItems()" variant="danger"><span class="text-nowrap">{{
                  $t('Delete All')
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refaccountCampaignListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')"
        >
          <template #cell(message)="data">
            <p
                v-b-popover.hover.bottom="data.item.message"
                variant="outline-primary"
                title="Notification Message"
            >
              {{ data.item.message | truncate(120, '...') }}
            </p>
          </template>
          <template #cell(is_read)="data">
            <b-form-checkbox value="1" unchecked-value="0" @change="markAsRead(data.item.id)" name="status"
                             class="custom-control-success" v-model="data.item.is_read"
            >
            </b-form-checkbox>
          </template>


          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <!-- <b-button
                :to="{ name: 'notification-preview',  }"
                v-b-tooltip.hover
                title="Preview Notification"
                variant="gradient-success"
                icon="EyeIcon"
                size="sm"
                class="mr-2"
            >
              {{ $t('Preview') }}
            </b-button> -->

            <b-button
                v-b-tooltip.hover
                v-on:click="deleteItem(data.item.id)"
                title="Delete Notification"
                variant="gradient-danger"
                icon="EyeIcon"
                size="sm"
                class="mr-2"
            >
              {{ $t('Delete') }}
            </b-button>


          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              {{ $t('Showing') }}
              <span v-if="items.length === 0"> {{ items.length }}</span>
              <span v-else-if="items.length > 0"> {{ perPage * (currentPage - 1) + (perPage ? 1 : 0) }}</span>
              {{ $t('to') }}
              <span v-if="items.length < perPage - 1"> {{ items.length }}</span>
              <span v-else-if="perPage * (currentPage) > items.length"> {{ items.length }}</span>
              <span v-else> {{ perPage * (currentPage - 1) + perPage }}</span>
              {{ $t('of') }} {{ items.length }} {{ $t('entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  VBPopover,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BFormCheckbox,
    VBTooltip,
    VBPopover,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 5,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      tableColumns: [
        {
          key: 'title',
          sortable: true,
          label: this.$i18n.t('Title'),
        },
        {
          key: 'created_at',
          sortable: true,
          label: this.$i18n.t('Date'),
          formatter: (value) => {
            return this.formateDateIfGerman(value)
          }
        },
        {
          key: 'message',
          sortable: true,
          label: this.$i18n.t('Notification Content'),
        },
        {
          key: 'is_read',
          sortable: true,
          label: this.$i18n.t('Mark as read'),
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions')
        },
      ],
      items: [],
    }
  },

  mounted() {
    this.getItems()
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
  },
  methods: {
    dataMeta() {
      return {
        from: this.perPage.value * (this.currentPage.value - 1) + (this.perPage ? 1 : 0),
        to: this.perPage.value * (this.currentPage.value - 1) + this.perPage,
        of: this.items.length,
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getItems() {
      this.items = []
      // this.loaded = false

      this.helperGetItems(`/notifications`)
      this.dataMeta()
    },

    deleteItem(id) {
      this.helperDeleteItem(
          `/notifications/${id}`,
      )
    },

    markAsRead(id) {
      this.$http
          .put(`/notifications/markasread/${id}`)
          .then((response) => {
            this.$bvToast.toast(this.$i18n.t('Notifications marked as read'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-top-right',
            })
            // this.loaded = false
            this.getItems()
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t('Notifications not marked as read'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-top-right',
            })
          })
      // console.log(`${id}`)
    },
    deleteAllItems() {
      if (this.items.length === 0){
        this.$swal({
          title: this.$i18n.t('You dont have any Messages to be deleted'),
          icon: 'warning',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
      }else {
        this.helperDeleteAllItem(
            `/notifications/delete`,
        )
      }
    },
    updateItems() {
      const isAllRead = this.items.some(item =>
          item.is_read == 0
      )
      if (this.items.length === 0 || !isAllRead) {
        this.$swal({
          title: this.$i18n.t('You dont have any Messages to be marked as read'),
          icon: 'warning',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
      } else {
        this.$http
            .put(`/notifications/markallasread`)
            .then((response) => {
              this.$bvToast.toast(this.$i18n.t('All notifications marked as read'), {
                title: this.$i18n.t('Success'),
                variant: 'success',
                toaster: 'b-toaster-top-right',
              })
              this.getItems()
            })
            .catch((e) => {
              this.$bvToast.toast(this.$i18n.t('All notifications not marked as read'), {
                title: this.$i18n.t('Error'),
                variant: 'danger',
                toaster: 'b-toaster-top-right',
              })
            })
      }
    },
    formatName(value, key, item) {
      if (item.firstname == null && item.name == null) return item.email

      if (!item.firstname) return item.name

      return item.firstname + ' ' + item.name

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
